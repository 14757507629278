@mixin hover-focus() {
  &:hover,
  &:focus {
    @content;
  }
}

@mixin hover() {
  &:hover {
    @content;
  }
}

$blue: #0a1a41;
$sjosmaragd: #AFC9C5;
$link-color: $blue;
$link-hover-color: darken($link-color, 15%);
$white-100: #FFFFFF;

$red-200: #D25252;
$dark-100: #2b2b2b;
$dark-200: #323232;
$dark-300: #3c3f41;
$dark-400: #424445;
$dark-500: #75797b;

body, html {
  background-color: $white-100;
}

body {
  a, .event-tabs button.config-option-features-button {
    color: $link-color;

    @include hover() {
      color: $link-hover-color;
    }
  }

  .btn-link {
    color: $link-color;

    @include hover() {
      color: $link-hover-color;
    }
  }

  .btn-primary {
    color: $sjosmaragd;
    background-color: $blue;
    border-color: $blue;
  }

  .btn-primary:hover {
    color: $sjosmaragd;
    background-color: darken($blue, 10%);
    border-color: darken($blue, 15%);
  }

  .btn-primary:focus, .btn-primary.focus {
    color: $sjosmaragd;
    background-color: darken($blue, 10%);
    border-color: darken($blue, 15%);
    box-shadow: 0 0 0 0.2rem rgba(121, 171, 255, 0.5);
  }

  .btn-primary.disabled, .btn-primary:disabled {
    color: $sjosmaragd;
    background-color: $blue;
    border-color: $blue;
  }

  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: $sjosmaragd;
    background-color: darken($blue, 10%);
    border-color: darken($blue, 15%);
  }

  .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
  .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(51, 128, 255, 0.5);
  }

  .form-control:focus, .form-control-lg:focus {
    box-shadow: 0 0 0 .2rem rgba(108, 117, 125, .5);
  }
}