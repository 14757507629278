div#cookie-consent-modal {
  color: #555;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

div#cookie-consent-modal .btn-success {
  background-color: #47a447 !important;
  border-color: #398439 !important;
}

/* The switch - the box around the slider */
div#cookie-consent-modal .switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;
  margin-top: 18px;
}

/* Hide default HTML checkbox */
div#cookie-consent-modal .switch input {
  display: none;
}

/* The slider */
div#cookie-consent-modal .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

div#cookie-consent-modal .slider:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

div#cookie-consent-modal input:checked + .slider {
  background-color: #2196F3;
}

div#cookie-consent-modal input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

div#cookie-consent-modal input:checked + .slider:before {
  -webkit-transform: translateX(30px);
  -ms-transform: translateX(30px);
  transform: translateX(30px);
}

/* Rounded sliders */
div#cookie-consent-modal .slider.round {
  border-radius: 34px;
}

div#cookie-consent-modal .slider.round:before {
  border-radius: 50%;
}

/*
Cookie consent
*/
div#cookie-consent-modal .consent-card {
  background: #FFF;
  padding: 14px;
  border: 1px solid #EEE;
  border-radius: 2px;
  min-height: 190px;
}

/*
Modal
  */
div#cookie-consent-modal.w3-modal {
  z-index: 1040;
  display: none;
  padding-top: 100px;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4)
}

div#cookie-consent-modal .w3-card-4, div#cookie-consent-modal
.w3-hover-shadow:hover {
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2), 0 4px 20px 0 rgba(0,
          0, 0, 0.19)
}

div#cookie-consent-modal .w3-modal-content {
  margin: auto;
  background-color: rgb(247, 247, 247);
  position: relative;
  padding: 20px 5px;
  outline: 0;
  width: 600px
}

div#cookie-consent-modal .w3-modal-content footer {
  padding: 15px;
}

@media (max-width: 768px) {
  div#cookie-consent-modal .w3-modal-content {
    width: auto
  }

  div#cookie-consent-modal .w3-modal {
    padding-top: 50px
  }
}

@media (min-width: 993px) {
  div#cookie-consent-modal .w3-modal-content {
    width: 900px
  }

  div#cookie-consent-modal .w3-hide-large {
    display: none !important
  }

  div#cookie-consent-modal .w3-sidebar.w3-collapse {
    display: block !important
  }
}

div#cookie-consent-modal .w3-animate-top {
  position: relative;
  animation: animatetop 0.4s
}

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0
  }
  to {
    top: 0;
    opacity: 1
  }
}

div#cookie-consent-modal .w3-container, div#cookie-consent-modal .w3-panel {
  padding: 0.01em 16px;
}

div#cookie-consent-modal .w3-large {
  font-size: 18px !important;
}

div#cookie-consent-modal .w3-button {
  border: none;
  display: inline-block;
  padding: 8px 16px;
  vertical-align: middle;
  overflow: hidden;
  text-decoration: none;
  color: inherit;
  background-color: inherit;
  text-align: center;
  cursor: pointer;
  white-space: nowrap
}

div#cookie-consent-modal .w3-tooltip, div#cookie-consent-modal
.w3-display-container {
  position: relative
}

div#cookie-consent-modal .w3-tooltip .w3-text {
  display: none
}

div#cookie-consent-modal .w3-tooltip:hover .w3-text {
  display: inline-block
}

div#cookie-consent-modal .w3-display-topright {
  position: absolute;
  right: 0;
  top: 0
}

div#cookie-consent-modal .w3-button.w3-display-topright:hover {
  background: #DDD;
}