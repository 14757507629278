#teacher-session-display-left, #teacher-session-display-right {
  width: 50%;
}

#video-wrapper-remote, #video-wrapper-local {
  position: relative;
  margin: auto;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  transform: translatez(0);
}

div#video-wrapper-remote div:first-child,
div#video-wrapper-local div:first-child {
  width: 100% !important;
  height: unset !important;
  aspect-ratio: 10 / 16;
}

div#video-wrapper-remote div:first-child div:first-child,
div#video-wrapper-local div:first-child div:first-child {
  position: relative !important;
  width: 100% !important;
  height: unset !important;
  aspect-ratio: 10 / 16;
}

#video-wrapper-remote video, #video-wrapper-local video {
  transform: translatez(0);
  position: relative;
  margin: auto;
  min-width: 100%;
  min-height: 100%;
}

div#student-session-display div#doc-wrapper div.tox-tinymce {
  width: 100%;
  height: calc(100vh - 125px) !important;
}

div#session div.tox-tinymce {
  border-radius: 5px;
}

div#student-session-display div#whiteboard-wrapper div#whiteboard {
  width: 100%;
  height: calc(100vh - 125px) !important;
  border: 1px solid #333333;
}

div#teacher-session-display div#whiteboard-wrapper div#whiteboard, div#teacher-session-display div#doc-wrapper div.tox-tinymce {
  width: 100%;
  height: calc(100vh - 175px) !important;
  border: 1px solid #333333;
}

div#doc-wrapper div.codox-session-tooltip {
  display: none;
}