body, html {
  height: 100%;
  background-color: #0A1A41;
  font-family: Arial, Helvetica, sans-serif;
  .user-wrapper {
    border-color: #FBFCFC;
    background-color: #FBFCFC;
    border-radius: 20px;
  }
}

html.parent, html.student, html.teacher {
  background-color: rgba(175, 201, 197, 1) !important;
}

html.teacher {
  background-color: rgba(175, 201, 197, 1) !important;
}

body.student, body.parent {
  background-color: rgba(175, 201, 197, 1) !important;

  #admin-content-frame {
    background-color: rgba(175, 201, 197, 1) !important;
  }
  .user-wrapper {
    border-color: rgba(175, 201, 197, 1) !important;
    background-color: rgba(175, 201, 197, 1) !important;
  }
  .calendar-wrapper{
    background-color: rgba(175, 201, 197, 1) !important;
  }
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, .9);
}

.navbar-brand {
  img {
    max-height: 30px;
    width: auto;
  }
}

.form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  opacity: 0.3; /* Firefox */
}

.style-card {

  h2 {
    font-size: 1.2rem;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  background-color: #FFF;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .14);
  border-radius: 3px;

  &-image-container {
    text-align: center;
    min-height: 15rem;
    max-height: 15rem;
    border-radius: 3px 3px 0 0;
    overflow: hidden;
    display: flex;
    justify-content: center;
    justify-items: center;
    position: relative;

    .no-image {
      background-image: linear-gradient(to bottom right, $primary, rgba(0, 105, 217, 0.3));
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
    }

    &-image {
      object-fit: cover;
      vertical-align: center;
    }

  }

  &-body {
    text-align: center;
    padding: 1rem;

    &-name {
      min-height: 3rem;
    }

    &-date {
      display: block;
    }
  }

}

.show-event {

  &-when-start {
    font-size: 1.5rem;

    &-icon {
      font-size: 1.5rem;
      color: $primary;
    }
  }

  &-when-end {
    font-size: 1rem;
    color: $gray-600;

    &-icon {
      font-size: .9rem;
    }
  }

  &-where {
    font-size: 1.2rem;

    &-icon {
      font-size: 1.5rem;
      color: $primary;
    }
  }

  &-image {
    border-radius: 3px;
    overflow: hidden;
  }

}

#show-event {
  @include media-breakpoint-down(sm) {
    .card-box {
      padding: 10px;

      #div_order_buttons {
        margin-bottom: 10px;
      }
    }
  }
}

.event-public-list-organizer {
  &-icon {
    font-size: 1.5rem;
    color: $primary;
  }

  &-website {
    word-wrap: break-word;
  }
}

#event-ticket-category-list {
  .container-category-list-wrapper {
    border-left: 10px solid #DDDDDD;
    display: none;
  }
}

.event-ticket-category-list {
  background-color: $gray-100;

  h2 {
    font-size: 1rem;
    word-break: break-word;
    hyphens: auto;
    font-weight: bold;
  }

  .sold_out {
    color: #ee2200;
    font-weight: bold;
    display: block;
    vertical-align: middle;
  }

  .ticket-qty-container {

    &.container-open {
      border-bottom: 0;
    }

    @include media-breakpoint-down(sm) {
      margin-top: 1rem;
      display: flex;
    }

    input {
      width: 5rem;
      display: inline-block;

      @include media-breakpoint-down(sm) {
        flex-grow: 1;
      }
    }

    .ticket-qty-button, .container-category-select {
      user-select: none;
      width: auto;
      background-color: $gray-200;
      @include media-breakpoint-down(sm) {
        flex: 1;

        &.remove-ticket {
          margin-right: 4px;
        }

        &.add-ticket {
          margin-left: 4px;
        }
      }


      i {
        color: $gray-800;
        font-size: 18px;

        &:hover {
          color: darken($gray-800, 5%);
        }

        &:active {
          color: darken($gray-800, 10%);
        }
      }

      &:hover {
        background-color: darken($gray-200, 5%);
      }

      &:active {
        background-color: darken($gray-200, 10%);
      }

      &:focus {
        box-shadow: none;
      }
    }
  }
}

#order-summary {

  border-radius: 3px;

  h2 {
    font-size: 1.2rem;
  }

  table {
    border: 1px solid $gray-200;
    background-color: $gray-100;
    box-sizing: border-box;
    border-radius: 3px;
    border-collapse: collapse;
    overflow: hidden;

    thead {
      td {
        font-weight: bold;
      }
    }
  }

}

#order-count-down, #queue-count-down {

  .form-control {
    background-color: $gray-100;
  }

  text-align: center;

  &-timer {
    font-size: 1.5rem;
  }

}

#reservation-alert-countdown {
  font-size: 1.2rem;
}

#ticket-list {
  .sort, .btn {
    cursor: pointer;
  }

  .modal-footer {
    justify-content: normal;
  }

  .material-icons-asc {
    transform: rotate(180deg);
  }

  .material-icons {
    vertical-align: sub;
    padding-bottom: 0;
  }

}

h1, h2 {
  word-wrap: break-word;
  hyphens: auto;
  margin-bottom: 1.2rem;
}

.material-icons {
  padding-bottom: 0.3rem;
}

#event-information {

  font-size: 1.2rem;

  ul {
    list-style: none;
  }

  .event-information-title {
    font-size: 1.5rem;
  }

}

.dropdown-divider {
  border-top: 1px solid $gray-700;
}

#organizer-payout-report {
  .sort, .btn {
    cursor: pointer;
  }

  .modal-footer {
    justify-content: normal;
  }

  .material-icons-asc {
    transform: rotate(180deg);
  }

  .material-icons {
    vertical-align: sub;
    padding-bottom: 0;
  }

}

.clickable-td {
  cursor: pointer;
}

.clickable-li {
  cursor: pointer;
}

.not-required {
  opacity: 0.45;
}

.auto-height {
  height: auto;
}

#organizer-sidebar-wrapper {
  @include media-breakpoint-down(sm) {
    display: none;
  }
}

#organizer-top-menu-wrapper {
  @include media-breakpoint-up(sm) {
    display: none;
  }
}

#restriction-code-form-button {
  color: $gray-600;
}

.restriction-code-ticket-category {
  border: 2px solid #46a0ff;
}

.html-new-line {
  white-space: pre-line;
}

#show-order-summary-toggle-button {
  color: $gray-600;
}

.breadcrumb {
  background-color: #ffffff;
}

#footer {
  font-size: 16px;
  background-color: #0A1A41;
  color: #ffffff;

  @include media-breakpoint-down(sm) {
    font-size: 16px;

    a {
      padding-top: 2px;
      padding-bottom: 2px;
      margin-top: 2px;
      margin-bottom: 2px;
      display: inline-block
    }
  }
}

#footer-organizer-information {
  @include media-breakpoint-down(sm) {
    ul {
      margin-bottom: 0;
    }
  }
}

@include media-breakpoint-down(sm) {
  div#menuLinks ul.navbar-nav a.btn-success {
    margin-top: .5rem;
  }
}

.sidebar ul.nav > li > button.config-option-features-button {
  color: rgba(255, 255, 255, 0.2);
  display: block;
}

.sidebar ul.nav > li > button.config-option-features-button:hover {
  color: #ffffff;
  text-decoration: none;
}

.event-tabs button.config-option-features-button {
  color: rgba(0, 123, 255, 0.3);
  display: block;
}

.event-tabs button.config-option-features-button:hover {
  color: #007bff;
  text-decoration: none;
}

header {
  background-color: #0A1A41;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 20px;

  svg {
    height: 50px;
    width: auto;
    color: rgba(175, 201, 197, 1);
    margin-right: 15px;
  }

  ul {
    vertical-align: baseline;
    margin-top: 20px;
    margin-bottom: 10px !important;
  }

  li {
    height: 50px;
    text-align: center;
    margin: 0 15px 0 15px;
  }

  li.button {
    text-transform: uppercase;
    width: 175px;
    text-align: center;
    vertical-align: center;
    color: rgba(175, 201, 197, 1) !important;
    border: 1px solid rgba(175, 201, 197, 1);
    box-sizing: border-box;
  }

  li.button-signup {
    text-transform: uppercase;
    width: 175px;
    text-align: center;
    vertical-align: center;
    color: #0A1A41 !important;
    background-color: rgba(175, 201, 197, 1);
    border: 1px solid rgba(175, 201, 197, 1);
    box-sizing: border-box;
  }

  li.button .nav-link {
    color: rgba(175, 201, 197, 1) !important;
  }

  li.button-signup .nav-link {
    color: #0A1A41 !important;
  }

  .tagline {
    color: rgba(175, 201, 197, 1);
    margin-top: 28px;
  }

  .navbar .container {
    align-items: start;
  }

  .container:not(.organizer-admin-header-container) {
    background: url(/public/images/header-bg.jpg) center top no-repeat $sjosmaragd;
    background-size: contain;
    min-height: 750px;
    padding-top: 30px;

    @include media-breakpoint-down(sm) {
      background-size: cover;
      min-height: 350px;
    }
  }

  .container.organizer-admin-header-container {
    margin: 0 auto;
    .logo img {
      max-height: 50px;
    }

    position: relative;
    ul {
      float: right;
    }

    li {
      float: left;
    }

    li.logout-right {
      right: 0;
      position: absolute;
    }

    a:not(.nav-link) {
      left: 15px; /* Match your padding on <ul> */
      top: 15px;
    }
  }

  .container {
    position: relative;

    .logo img {
      @include media-breakpoint-down(sm) {
        max-height: 80px;
      }
    }

    .menu-login {
      background: $blue;
      border-radius: 101px;
      color: $sjosmaragd;
      font-size: 2rem;
      display: flex;
      width: 140px;
      height: 140px;
      align-items: center;
      justify-content: center;

      @include media-breakpoint-down(sm) {
        width: 70px;
        height: 70px;
        font-size: 1em;
      }
    }

    .menu-teacher-link {
      position: absolute;
      bottom: 20px;
      left: 0;
      font-size: 2.4rem;
      display: flex;
      justify-content: center;
      align-items: center;

      @include media-breakpoint-down(sm) {
        font-size: 1.4rem;
        left: 15px;
      }

      &:before {
        content: '';
        display: inline-block;
        width: 50px;
        height: 50px;
        border-radius: 100px;
        background-color: $blue;
        margin-right: 15px;

        @include media-breakpoint-down(sm) {
          width: 20px;
          height: 20px;
        }
      }
    }

    .menu-we-can-help-link {
      position: absolute;
      bottom: 20px;
      right: 0;
      font-size: 2.4rem;
      display: flex;
      justify-content: center;
      align-items: center;

      @include media-breakpoint-down(sm) {
        font-size: 1.4rem;
        right: 15px;
      }

      &:after {
        content: '';
        display: inline-block;
        width: 50px;
        height: 50px;
        border-radius: 100px;
        background-color: $blue;
        margin-left: 15px;
        @include media-breakpoint-down(sm) {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}

header.student {
  background-color: rgba(175, 201, 197, 1);
}

.disabled-icon {
  cursor: default !important;
  .material-symbols-outlined{
    background-color: #c7c7cd !important;
  }
}

.disabled-screenshare-icon {
  cursor: default !important;

  .material-symbols-outlined {
    color: #c7c7cd !important;
  }
}

.green-icon {
  .material-symbols-outlined{
    background-color: limegreen;
  }
}

#user_edit_profile_form img {
  max-width: 400px;
  width: 100%;
  height: auto;
}

#user_edit_profile_form input[type=file] {
  border: none;
}

#user_register_form {

  img#profile-image {
    width: calc(300px / 16 * 11);
    height: 300px;
    border-radius: 12px;
    object-fit: cover;
  }

  .profile-photo-upload-button:hover, .profile-photo-capture-button:hover {
    cursor: pointer;
  }

  #profile-photo-upload .material-symbols-outlined, #profile-photo-capture .material-symbols-outlined {
    font-size: 70px;
  }
}

#user_edit_profile_form .profile-teacher-video {

  height: 200px;

div.profile-video-upload-icon {
    z-index: 300;
    color: #000000;
    font-size: 15px;
    font-weight: bold;
    position: absolute;
    width: 200px;
    text-transform: uppercase;
  }

div.profile-video-upload-text {
    z-index: 300;
    width: 200px;
    position: absolute;
    top: 85px;
  }

div#profile-video-capture {
    z-index: 200;
    position: absolute;
    transform: translateY(-50%);
    top: 110px;
  }

div.profile-video-capture-icon {
    z-index: 300;
    color: #000000;
    font-size: 15px;
    font-weight: bold;
    position: absolute;
    width: 200px;
    text-transform: uppercase;
  }

div.profile-video-capture-text {
    z-index: 300;
    width: 200px;
    position: absolute;
    top: 85px;
  }

  .profile-video-upload-button:hover,
  .profile-video-capture-button:hover{
    cursor: pointer;
  }

  .material-symbols-outlined {
    color: #000000;
    font-size: 70px;
  }

}

#user_edit_profile_form .profile-teacher-image {
  width: 200px;
  height: 200px;
  margin: auto;
  text-align: center;

  img {
    width: calc(300px / 16* 11);
    height: 300px;
    border-radius: 12px;
    object-fit: cover;
    z-index: 100;
    opacity: 1;
  }

  div#profile-photo-upload {
    z-index: 200;
    position: absolute;
    transform: translateY(-50%);
    opacity: 0;
  }

  div.profile-photo-upload-icon {
    z-index: 300;
    color: #000000;
    font-size: 15px;
    font-weight: bold;
    position: absolute;
    width: 200px;
    opacity: 0;
    text-transform: uppercase;
  }

  div.profile-photo-upload-text {
    z-index: 300;
    width: 200px;
    position: absolute;
    top: 60px;
    opacity: 0;
  }

  div#profile-photo-capture {
    z-index: 200;
    position: absolute;
    transform: translateY(-50%);
    opacity: 0;
    top: 110px;
  }

  div.profile-photo-capture-icon {
    z-index: 300;
    color: #000000;
    font-size: 15px;
    font-weight: bold;
    position: absolute;
    width: 200px;
    opacity: 0;
    text-transform: uppercase;
  }

  div.profile-photo-capture-text {
    z-index: 300;
    width: 200px;
    position: absolute;
    top: 60px;
    opacity: 0;
  }

  .profile-photo-upload-button:hover, .profile-photo-capture-button:hover {
    cursor: pointer;
  }

  .material-symbols-outlined {
    color: #000000;
    font-size: 70px;
  }
}

.admin-change-teacher {
  cursor: pointer;
  vertical-align: middle;
  font-size: 24px;
}

#profile-photo-capture-video {
  width: 640px;
  height: auto;
  max-width: 100%;
}

#profile-image-canvas {
  display: none;
}

#profile-camera {
  width: 640px;
  max-width: 100%;
  display: inline-block;
}

#user_edit_profile_form .profile-teacher-image:hover {
  img {
    opacity: 0.5;
  }

  div#profile-photo-upload,
  div#profile-photo-capture,
  div.profile-photo-upload-button,
  div.profile-photo-capture-button {
    opacity: 1;
  }

  /*div.profile-upload {
    opacity: 1;
  }

  div:not(.profile-upload) {
    opacity: 1;
  }*/
}

#availability-teachers .user-frame {
  border-width: 1px;
  border-style: solid;
  border-color: #FBFCFC;
  background-color: #FBFCFC;
  border-radius: 20px;
}


#my-students .user-frame, #my-student-requests .user-frame, #student-session-request .user-frame {
  border-width: 0 1px 1px 1px;
  border-style: solid;
  border-color: #C7C7CD;
  background-color: #FAF8F8;
  border-radius: 10px;
  min-height: 250px;
  box-shadow: 0 4px 8px -4px #999999;
  word-wrap: break-word;
}

.user-frame-image {
  width: 200px;
  height: 200px;
  margin: auto;
  text-align: center;
  img {
    width: 200px;
    height: 200px;
    /*border-radius: 50%;*/
    object-fit: cover;
  }
}

#my-students .user-frame-image, #my-student-requests .user-frame-image {
  width: unset;
  height: unset;

  img {
    width: calc(300px / 16 * 11);
    height: 300px;
    border-radius: 12px;
    object-fit: cover;
  }
}

.my-students-label{
  width: 100px;
  display: inline-block;
}

#session .session-element-border {
  border-width: 0 2px 2px 2px;
  border-style: solid;
  border-color: #C7C7CD;
  border-radius: 5px;
  box-shadow: 0 4px 8px -4px #999999;
}

#session .session-element-border h1 {
  font-size: 1.5rem;
}

#session-element-wrapper {
  width: calc((100vh - 55px) / 16 * 11) !important;
  height: calc(100vh - 55px) !important;
  position: relative;
  padding: 10px;
}

#session-element-wrapper.student {
  width: calc((100vh - 120px) / 16 * 11) !important;
  height: calc(100vh - 120px) !important;
}

#session-element-wrapper.desktop {
  margin: 0 auto;
}

#session-element-wrapper.desktop-whiteboard,
#session-element-wrapper.desktop-document {
  max-width: 900px;
  margin: 18px auto;
  padding: 0 0;
}

#session-element-wrapper.desktop-vertical-pdf {
  max-width: 900px;
  margin: -25px auto;
  padding: 0 0;
}

#session-element-wrapper textarea.tinymce, #session-element-wrapper-edit textarea.tinymce {
  border-width: 2px;
  border-style: solid;
  border-color: #AFC9C5;
  position: relative;
}

#session-element-wrapper-edit textarea.tinymce {
  height: auto;
}

#session-element-wrapper textarea.tinymce {
  height: calc((50vw - 50px) * 29 / 21) !important; /* A4 format */
}

#session-element-wrapper .spreadsheet-title {
  color: #000000;
}

#whiteboard-toolbar-wrapper {
  width: 100%;
  background-color: #ffffff;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  border-color: #AFC9C5;
  border-style: solid;
  border-top-width: 1px;
  border-right-width: 1px;
  border-left-width: 1px;
  border-bottom-width: 0;
}

#whiteboard-wrapper {
  width: 100%;
}

#whiteboard-wrapper #toolbar {
  cursor: pointer;
}

#session-element-wrapper div#whiteboard, #session-element-wrapper-edit div#whiteboard {
  width: 100%;
  height: calc(100vh - 115px);
  margin-bottom: 15px;
  border: 1px solid #AFC9C5;
  position: relative;
  overflow: hidden;
}

#session-element-wrapper.student div#whiteboard{
  height: calc(100vh - 75px) !important;
}

#next-session-button {
  color: #ffffff;
  border-radius: 5px;
  background-color: #56c200;
  padding: 12px;
  text-decoration: none;
  line-height: 41px;
}

#next-session-button.disabled {
  background-color: #0a1a41;
  pointer-events: none;
  cursor: default;
}

span#next-session-button{
  background-color: #0a1a41 !important;
}

#go-to-session-button-wrapper {
  font-size: 16px;
  display: inline-block;
  width: 150px;

  #go-to-session-button {
    color: #ffffff;
    border-radius: 5px;
    font-size: 26px;
    display: inline-block;
    width: 150px;
    height: 50px;
    line-height: 50px;
    background-color: #56c200;
    cursor: pointer;
  }

  .call-to-action {
    font-size: 13px;
  }
}

#session-start-screen {

  #countdown-to-session-start {
    background-color: #F27E30;
    color: #ffffff;
    border-radius: 5px;
    font-size: 26px;
    display: inline-block;
    width:150px;
    height: 50px;
    line-height: 50px;
  }

  #countdown-to-session-start.green {
    background-color: #56c200;
    cursor: pointer;
  }

  .has-join-session {
    color: #ffffff;
    .has-join-session-icon {
      .material-symbols-outlined {
        font-size: 50px;
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        transform: rotate(90deg);
      }
    }

    h1 {
      font-size: 70px;
    }

    h2 {
      font-size: 50px;
    }

    button#session-join-button, button#session-join-button:hover {
      width: 250px;
      color: #ffffff !important;
      border-color: #0A1A41;
      background-color: #0A1A41;
      text-decoration: none;

      .material-symbols-outlined {
        font-size: 26px;
        vertical-align: middle;
      }
    }
  }

  .student-name {
    font-size: 36px;
  }

}

#session-start-screen img {
  max-width: 150px;
  height: auto;
}

#session {
  width: 100%;
}

div#session-video-wrapper-remote {
  position: relative;
  margin: auto;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  transform: translatez(0);
  object-fit: scale-down;
}

div#video-wrapper-format.draggable {
  aspect-ratio: 11/16;
  cursor: move;
  z-index: 9998;
  max-height: 400px;
  height: 400px;
}

div#video-wrapper-format.resizable {
  aspect-ratio: 11/16;
  z-index: 9998;
  max-height: 400px;
  height: 400px;
  resize: vertical;
  overflow: auto;
}

span.video-resize-drag-area {
  height: 25px;
  width: 25px;
  display: inline-block;
  position: absolute;
  bottom: 0 !important;
  right: 0 !important;
  z-index: 9999 !important;
}

div#video-wrapper {
  position: relative;
  min-height: 300px;
  width: calc((100vh - 120px) / 16 * 11) !important;
  height: calc(100vh - 120px) !important;
  object-fit: cover;
}

div#video-wrapper-format {
  aspect-ratio: 11/16;
  max-height: 100%;
  height: 100%;
  position: relative;
  right: 0;
}

div#video-wrapper-format.screen-sharing {
  aspect-ratio: unset !important;
  max-height: unset !important;
  height: unset !important;
  width: 100%;
}

div#session-right-screen div#video-wrapper-format{
  height: 100% !important;
  width: 100% !important;
  aspect-ratio: 11/16 !important;
}

div#session-video-wrapper {
  position: absolute;
  /*margin: auto;*/
  width: calc(250px / 16 * 11);
  height: 250px;
  bottom: 0;
  right: 0;
  overflow: hidden;
  transform: translatez(0);
  z-index: 9998;
}

/*div#session-video-wrapper div:first-child{
  width: unset !important;
  height: calc(100vh - 220px) !important;
  aspect-ratio: 9 / 16;
}*/

div#session-video-wrapper-remote div:first-child {
  width: 100% !important;
  height: 100% !important;
}

div#session-video-wrapper div:first-child div:first-child {
  position: relative !important;
  width: unset !important;
  height: unset !important;
  max-height: 100% !important;
  aspect-ratio: 11 / 16;
}

div#session-video-wrapper-remote div:first-child div:first-child {
  position: relative !important;
  width: unset !important;
  height: unset !important;
  max-height: 100% !important;
  aspect-ratio: 11 / 16;
}

div#session-video-wrapper-remote div:first-child div:first-child, div#session-video-wrapper div:first-child div:first-child {
  background-color: transparent !important;
}

div#session-video-wrapper-remote.desktop div:first-child, div#session-video-wrapper-remote.desktop div:first-child div:first-child {
  aspect-ratio: 16 / 11;
}

div#session-video-wrapper-remote.desktop div:first-child div:first-child {
  border-radius: 12px;
  width: 100% !important;
  height: 100% !important;
  position: relative !important;
}

div#small-video.desktop #session-video-wrapper-remote div:first-child div:first-child {
  aspect-ratio: 16 / 11;
}

div#session-video-wrapper video, div#session-video-wrapper-remote video {
  transform: translatez(0);
  position: relative;
  margin: auto;
  object-fit: cover !important;
  height: 100%;
  width: 100%;
}

div#session-video-wrapper video, #small-video video, #video-wrapper-format.draggable #session-video-wrapper-remote video {
  border-radius: 12px;
}

#session-start-screen-video-wrapper video {
  object-fit: cover !important;
  width: 200px;
  height: 355px;
}

#video-check-wrapper video {
  object-fit: cover !important;
  width: 200px;
  height: 355px;
  border-radius: 12px;
}

#session-start-screen {
  #video-check-wrapper video {
    object-fit: cover !important;
    width: 160px;
    height: 284px;
  }
}

#volume-visualizer {
  --volume: 0%;
  position: relative;
  width: 200px;
  height: 20px;
  background-color: #7e7979;
}

#session-start-screen #volume-visualizer, #session-right-screen #volume-visualizer {
  margin: 0 auto;
}

#volume-visualizer::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: var(--volume);
  background-color: green;
  transition: width 100ms linear;
}

#network-check-wrapper span.network-speed-check, #session-network-check-wrapper span.network-speed-check {
  animation: blinker 1s linear infinite;
  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }
}

#network-check-wrapper {
  #network-speed-check-ok, #network-speed-check-not-ok {
    font-size: 24px;
  }

  i.material-icons {
    font-size: 72px;
    color: #28a745;
    vertical-align: middle;
  }
}

/*#network-check-wrapper i.material-icons {
  font-size: 72px;
  color: #28a745;
  vertical-align: middle;
}*/

#network-check-ok i.material-icons {
  font-size: 36px;
  color: #28a745;
  vertical-align: middle;
}

#network-check {
  max-width: 200px;
  margin: 0 auto;
}

div#session-video-wrapper-remote video.screen-sharing {
  object-fit: contain !important;
  height: unset !important;
}

#session-name {
  font-size: 24px;
}

#session-name, .session-element-title {
  cursor: pointer;
}

#delete-session-button, #move-session-button {
  color: #999999;
}

.session-element-list-wrapper {
  border-color: #DCDBDD;
  background-color: #DCDBDD;
  border-radius: 20px;
  overflow: hidden;
}


.session-journal {
  border-color: #DCDBDD;
  background-color: #DCDBDD;
  border-radius: 20px;
  overflow: hidden;
}

.session-journal-wrapper {
  background-color: #DCDBDD;
  border-color: #C7C7CD;
  border-radius: 10px;
  border-width: 0 1px 1px 1px;
  border-style: solid;
  box-shadow: 0 4px 8px -4px #999999;
}

.session-journal-wrapper p {
  clear: both;
}

#session-journal-button {
  width: 150px;
  height: 150px;
  line-height: 150px;
  border-radius: 50%;
  font-size: 24px;
  text-align: center;
  background: #AFC9C5;
  display: inline-block;
  cursor: pointer;
}

.session-note {
  border-color: #DCDBDD;
  background-color: #DCDBDD;
  border-radius: 20px;
  overflow: hidden;
}

.session-note-wrapper {
  background-color: #DCDBDD;
  border-color: #C7C7CD;
  border-radius: 10px;
  border-width: 0 1px 1px 1px;
  border-style: solid;
  box-shadow: 0 4px 8px -4px #999999;
}

.session-note-wrapper p {
  clear: both;
}

#session-note-button {
  width: 150px;
  height: 150px;
  line-height: 150px;
  border-radius: 50%;
  font-size: 24px;
  text-align: center;
  background: #AFC9C5;
  display: inline-block;
  cursor: pointer;
}

.session-element {
  background-color: #DCDBDD;
  border-color: #C7C7CD;
  border-radius: 10px;
  border-width: 0 1px 1px 1px;
  border-style: solid;
  box-shadow: 0 4px 8px -4px #999999;
}

.session-element p {
  clear: both;
}

.calendar-wrapper {
  border-color: #FBFCFC;
  background-color: #FBFCFC;
  border-radius: 20px;
}

.calendar-wrapper.student {
  #user-calendar {
    border-color: #FBFCFC;
    background-color: #FBFCFC;
    border-radius: 20px;

    .fc-header-toolbar .fc-button-group {
      margin-right: 20px;
      margin-bottom: 20px;

      .fc-button-primary {
        color: #AFC9C5;
        background-color: #030815;
        border-style: none;
      }
    }

    .fc-timegrid-col.fc-day-today {
      background-color: #ffffff !important;
    }

    thead .fc-timegrid-axis {
      background-color: #EBF2F1;
      height: 50px;
    }

    .fc-col-header-cell {
      background-color: #EBF2F1;
      height: 50px;
      vertical-align: middle;
      font-size: 15px
    }

    th.fc-day-today {
      background-color: #AFC9C5;

      .fc-col-header-cell-cushion {
        color: #ffffff;
      }
    }
  }

  .fc-v-event .fc-event-title-container {
    text-align: center;
  }
}

.calendar-wrapper.parent {
  #user-calendar {
    border-color: #FBFCFC;
    background-color: #FBFCFC;
    border-radius: 20px;

    .fc-header-toolbar .fc-button-group {
      margin-right: 20px;
      margin-top: 20px;

      .fc-button-primary {
        color: #AFC9C5;
        background-color: #030815;
        border-style: none;
      }
    }

    .fc-timegrid-col.fc-day-today {
      background-color: #ffffff !important;
    }

    thead .fc-timegrid-axis {
      background-color: #EBF2F1;
      height: 50px;
    }

    .fc-col-header-cell {
      background-color: #EBF2F1;
      height: 50px;
      vertical-align: middle;
      font-size: 15px
    }

    th.fc-day-today {
      background-color: #AFC9C5;

      .fc-col-header-cell-cushion {
        color: #ffffff;
      }
    }
  }

  .fc-v-event .fc-event-title-container {
    text-align: center;
  }
}

.fc-view-harness {
  background-color: #ffffff;
  border-color: #ffffff;
  border-radius: 5px;
  overflow: hidden;
}

h1.white-header {
  color: #ffffff;
}

#whiteboard {
  background-color: #ffffff;
  border-color: #ffffff;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  overflow: hidden;
}

.session-wrapper {
  border-color: #DCDBDD;
  background-color: #DCDBDD;
  border-radius: 20px;
  display: flex;
}

.user-wrapper {

  image {
    border-radius: 12px;
  }
}

.student-wrapper {
  border-color: #FBFCFC;
  background-color: #FBFCFC;
  border-radius: 20px;
}

#session-join-button {
  text-transform: uppercase;
  text-decoration: underline;
  font-size: 12px;
  text-align: center;
  vertical-align: center;
  color: #0A1A41 !important;
}

#session-join-button:hover {
  color: #0A1A41 !important;
}

.session-student {
  color: rgba(175, 201, 197, 1);
}

.session-student .material-icons {
  color: rgba(175, 201, 197, 1);
}

#user_register_form input, #user_register_form textarea, #user_edit_profile_form input, #user_edit_profile_form textarea, #user_login_form input, #user_new_password_form input {
  margin-top: -20px;
  padding-left: 24px;
  border-width: 2px;
  border-radius: 10px;
}

#user_register_form input, #user_edit_profile_form input, #user_login_form input, #user_new_password_form input {
  height: 45px;
}

#user_register_form button, #user_login_form button, #user_new_password_form button, #user_edit_profile_form button, #user-register-form-wrapper button {
  margin-top: -20px;
  border-width: 2px;
  border-radius: 10px;
  height: 45px;
  min-width: 150px;
}

#user_register_form span.input-group-text, #user_edit_profile_form span.input-group-text {
  height: 45px;
}

#user_login_form a{
  text-decoration: underline;
}


#user_register_form .input-group input, #user_edit_profile_form .input-group input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

#user_edit_profile_form img {
  margin-top: -20px;
}

#user_register_form div.input-group-prepend, #user_edit_profile_form div.input-group-prepend {
  margin-top: -20px;
}

#user_register_form select, #user_edit_profile_form select {
  margin-top: 15px;
}

#user_register_form label, #user_edit_profile_form label, #user_login_form label, #user_new_password_form label {
  margin-left: 20px;
  padding: 0 5px 0 5px;
  background-color: #ffffff;
  position: relative;
  z-index: 99;
}

#user_register_form label.label-phone, #user_edit_profile_form label.label-phone {
  margin-left: 73px;
}

#user_register_form .red-border {
  border-color: red;
}

#user_edit_profile_form .input-group-text {
  background-color: #ffffff;
  border-top-width: 2px;
  border-bottom-width: 2px;
  border-left-width: 2px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.background-image {
  background-image: url("/public/images/student.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.background-color-blue {
  background-color: #E2F4F8;
}

.background-color-dark-blue {
  background-color: #122348;
}

.background-color-green {
  background-color: #AFC9C5;
}

#teacher-profiling-page {
  .name {
    font-size: 36px;
  }

  img {
    border-radius: 12px;
  }

  .material-symbols-outlined {
    vertical-align: middle;
  }
}

#select-teacher-modal {
  background-color: #afc9c5;
  color: #122348;

  .modal-header {
    font-size: 36px;
    color: #122348 !important;
  }

  .material-symbols-outlined {
    font-size: 36px;
    color: #122348 !important;
  }

  .modal-header {
    color: #122348 !important;
  }

  .modal-content, .modal-header, .modal-body {
    background-color: #afc9c5;
    border: none;
  }

  .modal-body img {
    border-radius: 12px;
  }
}

#select-teacher-calendar-wrapper {
  background-color: #ffffff;
  border-radius: 3px;

}

#select-teacher-calendar-clock-wrapper {
  button {
    border: 1px solid rgba(175, 201, 197, 1);
    border-radius: 5px;
  }

  button:disabled {
    background-color: #DEDEDE;
  }
}

#select-teacher-calendar, #dashboard-calendar {
  clear: both;
}

.select-teacher-profile {
  height: 200px;
  position: relative;
}

.select-teacher-profile div {
  bottom: 0;
  width: 100%;
  background-color: #000000;
  color: #ffffff;
  position: absolute;
}

#user-calendar {
  .fc-button-primary {
    color: #000000;
    background-color: #FBFCFC;
    border-style: none;
  }

  .fc-timegrid-event {
    border: none;
    height: 100%;
    width: 100%;
    margin: 0 !important;
    padding: 0 !important;
    border-radius: 0;
  }

  tbody {
    td.fc-timegrid-slot-label {
      padding: 0 15px;
    }
  }
}

#user-availability-calendar{
  .fc-timegrid-event{
    border: none;
    background-color: #AFC9C5;
    height: 100%;
    width: 100%;
    margin:0 !important;
    padding:0 !important;
    border-radius: 0;
  }

  thead tr {
    background-color: #000000;

    :first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    :last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    th {
      border: none;
    }
  }

  tbody {
    td.fc-timegrid-slot-label {
      padding: 0 15px;
    }
  }

  .fc-col-header-cell-cushion {
    color: #ffffff;
    text-transform: uppercase;
    font-size: 14px;
    padding: 10px 0;
  }
}

div#user-availability-calendar.fc-direction-ltr, div#user-calendar.fc-direction-ltr {
  margin: 0 !important;

  div .fc-timegrid-col-events {
    margin: 0 !important;
  }
}

#select-teacher-calendar, #dashboard-calendar {

  a:not(.is-available) {
    color: #999999 !important;
  }

  .fc-button-primary {
    color: #000000;
    background-color: #ffffff;
    border-style: none;
  }

  .fc-toolbar-title {
    font-size: 18px;
  }

  .fc-daygrid-day-top {
    display: inherit;
    text-align: center;
  }

  .fc-col-header {
    margin-bottom: 15px;
  }

  .fc-daygrid-day-frame {
    height: 60px;
  }

  .fc-daygrid-day-top {
    margin-top: 15px
  }

  .fc-scrollgrid, .fc-scrollgrid td, .fc-scrollgrid th,
  {
    border-style: none !important;
    border-width: 0 !important;
    border-color: #ffffff !important;
  }

  .fc-day-today {
    background-color: #000000;
    width: 60px;
    height: 60px;
    line-height: 30px;
    border-radius: 50%;

    .fc-daygrid-day-top a {
      color: #ffffff !important;
    }
  }

}

.select-teacher-profile img {
  height: 200px;
  width: 100%;
  object-fit: cover;
  border-radius: 12px;
}

#select-teacher-step-1 {
  font-size: 24px;
}

#select-available-teachers{
  cursor: pointer;
}

.select-teacher-profile .material-symbols-outlined {
  font-size: 36px;
  position: absolute;
  right: 0;
  top: 0;
}

.teacher-information-button, .select-teacher-profile img {
  cursor: pointer;
  max-width: 222px;
}

#select-available-teachers {
  text-decoration: underline;
}

#user_register_success_message h1 {
  font-size: 25px;
}

#session-element-video-embed-form {
  width: 100%;
}

#session_document_form {
  margin: 0;
}

.tox-tinymce {
  width: 100%;
  height: calc(100vh - 110px) !important;
}

#session-element-wrapper.student .tox-tinymce{
  height: calc(100vh - 35px) !important;
}

.codox-session-info-list {
  margin-bottom: 15px !important;
}

.codox-session-info-avatar {
  margin-left: 10px !important;
}

.codox-container {
  display: none !important;
}

.session-student .tox-toolbar__group {
  margin-left: auto !important;
}

.session-student .tox-toolbar__primary span.session-element-name {
  padding: 5px 0 0 10px;
}

.session-student .tox-toolbar__primary {
  background: none !important;
}

.session-element-content-text {
  width: 90px;
  display: inline-block;
  margin: 0 15px;
  padding-right: 10px;
  text-align: right;
  color: #ACAFBA;
  border-left: 1px solid #C7C7CD;
  border-right: 1px solid #C7C7CD;
}

.session-element-title {
  width: 220px;
  display: inline-block;
}

.session-element-start, .session-element-reorder, .session-element-delete {
  color: #C7C7CD;
}

#new-session-element-button i.material-icons,
#new-session-journal-button i.material-icons,
#new-session-note-button i.material-icons {
  font-size: 36px !important;
}

.session-student-name {
  font-size: 24px;
}

.session-student-date, .session-start-countdown-text {
  font-size: 14px;
}

.session-start-countdown {
  font-size: 18px;
}

.session-student-image img {
  width: calc(300px / 16* 11);
  height: 300px;
  border-radius: 12px;
  line-height: 200px;
  object-fit: cover;
}

#session-av-input-modal .modal-body,
#session-av-input-modal .modal-header,
#session-av-input-modal .modal-footer,
#session-av-input-modal .modal-content,
#session-element-form-modal .modal-body,
#session-element-form-modal .modal-header,
#session-element-form-modal .modal-footer,
#session-element-form-modal .modal-content,
#session-journal-form-modal .modal-body,
#session-journal-form-modal .modal-header,
#session-journal-form-modal .modal-footer,
#session-journal-form-modal .modal-content,
#session-note-form-modal .modal-body,
#session-note-form-modal .modal-header,
#session-note-form-modal .modal-footer,
#session-note-form-modal .modal-content {
  background-color: #B6CCC9;
  border: none;
}

#session-join-request-modal .modal-body,
#session-join-request-modal .modal-header,
#session-join-request-modal .modal-footer,
#session-join-request-modal .modal-content {
  background-color: #DCDBDD;
  border: none;
}

#session-element-form-modal ::placeholder {
  color: #dddddd;
  opacity: 1;
}

#session-element-form-modal select,
#session-element-form-modal select option {
  color: #000000;
}

#session-element-form-modal select:invalid,
#session-element-form-modal select option[value=""] {
  color: #dddddd;
}

.session-controls .material-symbols-outlined, .session-controls .material-icons {
  font-size: 36px;
}

.session-element-start-for-student .material-symbols-outlined {
  line-height: 30px;
  border-radius: 50%;
  background-color: #000000;
  color: #ffffff;
  height: 30px;
  width: 30px;
}

#session-chat .material-symbols-outlined {
  background-color: unset !important;
  color: #000000;
}

#session-timer-wrapper {
  line-height: 30px;
  height: 30px;
  width: 70px;
  vertical-align: center;
}

.session-wrapper .student-name {
  font-weight: bold;
  font-size: 18px;
}

#session-timer-wrapper .material-symbols-outlined {
  color: #999999;
  font-size: 16px !important;
}

#session-timer-wrapper .material-symbols-outlined.active {
  color: #32CD32;
  font-size: 16px !important;
  opacity: 0.3;
}

#session-timer-wrapper .material-symbols-outlined.student_active {
  color: #32CD32;
  font-size: 16px !important;
}

#session-timer-wrapper .material-symbols-outlined {
  font-variation-settings: 'FILL' 1,
  'wght' 400,
  'GRAD' 0,
  'opsz' 48
}

#admin-student-button {
  text-decoration: underline;
  margin-top: 50px;
  font-size: 16px;
}

.dashboard-teacher-name {
  font-size: 24px;
}

.dashboard-menu {

  svg {
    max-height: 50px;
  }

  .dashboard-menu-sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }

  border-right: 2px solid #E6E5E5;

  a {
    text-decoration: none;
    cursor: pointer;

    .material-symbols-outlined {
      font-size: 18px;
      margin-top: 2px;
      vertical-align: top;
    }
  }
}

.student-dashboard-menu {

  svg {
    max-height: 50px;
  }

  border-right: 2px solid #0A1A41;

  a {
    text-decoration: none;
    cursor: pointer;

    .material-symbols-outlined {
      font-size: 18px;
      margin-top: 2px;
      vertical-align: top;
    }
  }
}

.student-web {
  img {
    border-radius: 12px;
    aspect-ratio: 11/16;
    max-height: 300px;
    height: 300px;
    object-fit: cover;
  }
}

.dashboard-statistic {
  width: 100%;
  height: 100px;
  border-radius: 15px;
  color: #253656;
  background-color: #AFC9C5;
  box-sizing: border-box;
  font-size: 14px;

  .material-symbols-outlined {
    font-size: 36px;
  }
}

.dashboard-revenue {
  width: 100%;
  border-radius: 15px;
  color: #253656;
  background-color: #EDEDEE;
  box-sizing: border-box;
}

.dashboard-next-session-button {
  background-color: #F27E30;
  border-radius: 5px;
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;

  .material-symbols-outlined {
    font-size: 18px;
    margin-top: 2px;
    vertical-align: top;
  }
}

.dashboard-information {
  color: #D2D1D1;
  font-size: 16px;
}

.profile-form-information {
  color: #F27E30;
  font-size: 16px;
}

.dashboard-number-of-students {
  background-color: #F27E30;
  border-radius: 15px;
  color: #ffffff;
  text-decoration: none;
  width: 240px;
  height: 40px;
  line-height: 40px;
  display: inline-block;

  .material-symbols-outlined {
    font-size: 30px;
    margin-top: 2px;
    vertical-align: top;
  }
}

.dashboard-profile-image {
  height: 75px;
  width: 75px;
  img{
    border-radius: 50%;
    object-fit: cover;
  }

}

.dashboard-upcoming-session-student-list {

  .upcoming-session-date {
    font-size: 14px;
  }

  .upcoming-session-wrapper {
    border: 2px solid #E0E0E0;
    border-radius: 10px;
    cursor: pointer;
  }

  .upcoming-session-start {
    font-size: 14px;
    color: #999999;
  }

  .upcoming-session-student-name {
    font-size: 20px;
  }
}

#dashboard-calendar {

  .fc-daygrid-day-number {
    color: #999999;
  }

  a.has-event {
    color: #000000 !important;
    font-weight: bold;
  }

  .fc-day-disabled {
    background-color: #ffffff;
  }

  .fc-day-today {
    .fc-daygrid-day-number {
      background-color: #000000;
      border-radius: 50%;
      line-height: 18px;
      display: inline-block;
      height: 26px;
      width: 26px;
    }

    background-color: #ffffff;
  }
}

#small-video {
  width: calc(200px / 16 * 11);
  height: 200px;
  position: fixed;
  bottom: 0;
  right: 10px;
  z-index: 9997;
}

#small-video.desktop {
  height: calc(200px / 16 * 11);
  width: 200px;
}

#session-right-screen {
  height: calc(100vh - 55px);
  width: 50%;
  box-shadow: inset 5px 5px 10px 2px #999999;
  overflow-y: auto;
  overflow-x: hidden;
  position: sticky;
  top: 45px;

  #youtube-player-wrapper {
    float: none;
    clear: both;
    width: 100%;
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25px;
    height: 0;
  }

  #youtube-player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  div#video-wrapper {
    width: 100% !important;
    height: 100% !important;
  }

  div#session-video-wrapper-remote {
    top: 0 !important;
  }

}

#session-left-screen {
  width: 50%;
  margin-right: 20px;

  div#video-wrapper {
    height: 0 !important;
    width: 0 !important;
  }

}

#session-element-wrapper-edit {
  width: calc((100vw - 120px) - calc((100vh - 120px) * 0.6875));
}

#select-teacher-calendar {
  .fc-day-past a {
    color: #B2B2B2 !important;
  }

  .fc-day-disabled {
    background-color: #ffffff;
  }
}

#youtube-player-wrapper iframe {
  max-width: 100%;
}

#youtube-player-wrapper.student-desktop {
  float: none;
  clear: both;
  width: 100%;
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;

  #youtube-player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.dysmate-embed-teacher {
  width: 100%;
  height: calc(100vh - 65px);
}

.dysmate-embed-student {
  width: 100%;
  height: calc(100vh - 130px);
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.session-element-alert-message .material-symbols-outlined{
  animation: rotation 2s infinite linear;
}

.media-button-lightbox-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: .5;
}

.media-button-lightbox-wrapper {
  z-index: 1050;
  position: fixed;
  left: 50%;
  top: 50%;
  margin: 0 auto;
  transform: translate(-50%, -50%);
}

div#media-button-lightbox .allow-media-button {
  font-size: 80px;
}

#admin-content-frame.blue {
  background-color: #E2F4F9 !important;
}

#admin-content-frame.orange {
  background-color: #F27E30 !important;
}

#session-screen-share.active, #session-screen-share-for-student-button.active {
  i.material-symbols-outlined {
    color: #32CD32;
  }
}

#user-register-form-wrapper .link-primary {
  color: #007bff;
}

.teacher-no-available-sessions {
  color: #999999;

  img {
    opacity: 0.4;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
  }
}

#session-form-modal {
  .material-symbols-outlined {
    font-size: 18px;
  }

  .session-start-time, .session-end-time {
    cursor: pointer;
  }
}

.fc-event-title .material-symbols-outlined {
  display: inline-block;
  padding-right: 3px;
  font-weight: 100;
  float: right;
  font-size: 22px;
  cursor: pointer;
}

.session-chat-message-wrapper {
  color: #000000;
  margin-bottom: 7px;
  width: 75%;

  .session-chat-message-date {
    font-size: 10px;
  }

  .white {
    color: #ffffff;
  }

  .session-chat-message {
    padding: 7px;
    border-radius: 10px;
    background: #ffffff;
  }
}

.student-medium-screen-menu-item {
  font-size: 16px !important;
  color: #0A1A41;

  .material-symbols-outlined {
    font-size: 18px !important;
  }

  a, a:hover {
    text-decoration: none;
  }
}

.my-teacher-web {
  font-size: 16px;

  .teacher-name {
    font-size: 24px;
  }
}

.student-small-screen-menu-item {
  color: #0A1A41;
}

#student-close-session-button {
  top: 0;
  right: 0;
  position: absolute;
  color: #ffffff;
  cursor: pointer;
  z-index: 9999;

  .material-symbols-outlined {
    font-size: 36px !important;
  }
}

#new-session-element-for-student-button {
  top: 0;
  right: 90px;
  position: absolute;
  z-index: 9999;
}

.reservation-payment-error {
  color: #ee2200;
}

.reservation-payment-error-icon {
  color: #ee2200;
  vertical-align: middle;
  font-size: 24px;
}

#new-session-element-for-student-button.no-screensharing {
  right: 40px !important;
}

#session-screen-share-for-student-button {
  top: 0;
  right: 40px;
  position: absolute;
  color: #ffffff;
  cursor: pointer;

  .material-symbols-outlined {
    font-size: 36px !important;
  }

}

#user-registration-close-form-button, #user-login-close-form-button, #user-new-password-close-form-button {
  top: 0;
  right: 0;
  position: absolute;
  color: #ffffff;
  cursor: pointer;

  .material-symbols-outlined {
    font-size: 42px !important;
  }
}

#waiting-to-access {
  font-size: 24px;
  color: #0A1A41;
  top: -80px;
  position: absolute;
}

.password-input-wrapper {
  input {
    position: relative;
    background: transparent;
    width: 100%;
  }

  .material-symbols-outlined {
    position: absolute;
    height: 100%;
    width: 32px;
    right: 15px;
    top: 20px;
    cursor: pointer;
  }
}

input#user_notification_email, input#user_notification_sms {
  height: 25px !important;
  margin-top: 0 !important;
}

@include media-breakpoint-up(sm) {
  .student-medium-screen-menu-item{
    display: block;
  }
  .student-small-screen-menu-item{
    display: none;
  }
  ul.navbar-nav.student {
    margin: 0 auto;
  }
}

@include media-breakpoint-down(sm) {
  .student-small-screen-menu-item{
    display: block;
  }
  .student-medium-screen-menu-item{
    display: none;
  }
}

@include media-breakpoint-down(md) {
  div div#session-video-wrapper-remote {
    object-fit: unset !important;
  }

  div div#video-wrapper-format {
    aspect-ratio: unset !important;
    object-fit: unset !important;
    width: 100%;
    height: 100%;
  }

  div div#session-video-wrapper video {
    //object-fit:unset!important;
  }

  div#admin-content-frame .flex-grow-1 {
    margin-top: 53px;
  }

  div header .tagline {
    margin-top: 0 !important;
  }

  div div#session.session-student {
    margin-top: 0 !important;
  }

  div .session-start-screen-row {
    margin-bottom: 0 !important;
  }

  div div#session-video-wrapper-remote video {
    background-color: unset;
  }

  div div#video-wrapper {
    height: calc(100vh - 97px);
  }

  div #admin-content-frame #admin-content {
    margin-top: 0;
  }

  div div#session-video-wrapper-remote {
    max-height: 100%;
  }

  div div#session-video-wrapper-remote div:first-child {

  }

  div div#session-video-wrapper-remote div:first-child div:first-child {
    width: 100% !important;
    height: 100% !important;
  }

}

@include media-breakpoint-down(sm) {
  div div#session-video-wrapper {
    width: calc(200px / 16 * 11);
    height: 200px;
  }
}

@include media-breakpoint-up(lg) {
  div div#video-wrapper-format {
    margin: 0 auto;
  }
}

#session-left-screen {
  min-width: 50px;
}

#session-right-screen {
  flex: 1;
}

#session-divider {
  width: 0px;
  cursor: ew-resize;
  background-color: #888;
  position: relative;
}

#session-divider-icon {
  position: absolute;
  top: 19px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 31px;
  color: #000000;
  z-index: 1000;
}

#open-right-screen-button .material-symbols-outlined {
  transform: rotateZ(180deg);
}

div#session-right-screen.width-due-to-resize {
  max-height: calc(100% - 45px);
}

div#session-right-screen div#session-video-wrapper-remote div:first-child div:first-child {
  width: 100% !important;
}

span.session-journal-popover, span.session-note-popover {
  position: absolute;
  cursor: pointer;
}