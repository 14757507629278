div.key-figures div#key-figures-number-of-events {
  @include media-breakpoint-down(md) {
    margin-bottom: 20px;
  }
}

div#admin-content-frame div.form-check-inline {
  @include media-breakpoint-down(md) {
    margin-right: 0;
  }
}

div#admin-content-frame div.form-check {
  padding-left: 0.85rem;
}
